import { observable, action } from "mobx";
import { themes } from "./ThemeService";

type Theme = keyof typeof themes;

class SliderService {
    @observable
    currentSlide: number = 0;

    @observable
    prevSlide: number = 0;

    @observable
    slides: Theme[] = [];

    @action
    addSlide = (theme: keyof typeof themes) => {
        this.slides.push(theme);
    }

    @action
    updateCurrentSlide = (currentSlide: number) => {
        this.currentSlide = currentSlide;
    }

    @action
    updatePrevSlide = (currentSlide: number) => {
        this.prevSlide = currentSlide;
    }

    @action
    resetSlides = () => {
        this.slides = [];
    }

    @action
    isOverflow = (ref: React.RefObject<HTMLDivElement>, windowHeight: number) => {
        if (ref.current && ref.current.scrollHeight > windowHeight - 240) return true;
        return false;
    }
}

export const sliderService = new SliderService;
