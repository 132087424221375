import React, { Component } from 'react';
import { observer } from "mobx-react";
import isTouchDevice from "is-touch-device";
import { viewportService } from "../../services/ViewportService";
import { ValuesVennDiagram } from "../Components/ValuesVennDiagram/ValuesVennDiagram";

interface ValueContentProps {
    title: string;
    subhead: string;
    content: string;
    extendPadding?: boolean;
}

@observer
export class ValueContent extends Component<ValueContentProps> {
    render() {
        const { title, subhead, content, extendPadding } = this.props;
        const { width, height } = viewportService;
        const contentFontSize = width < 800 ? 18 : 30;
        const marginBottom = width < 800 ? 30 : 60;
        const paddingBottom = width < 768 && extendPadding ? 268 : extendPadding ? 200 : isTouchDevice() ? 120 : 0;
        return (
            <div
                className="display--flex align-items--center"
                style={{
                    maxWidth: 1016,
                    minHeight: height - 240,
                    paddingBottom: paddingBottom,
                }}
            >
                <div>
                    <ValuesVennDiagram isFixed={false} />
                    <p
                        className="color__grey--4 font-weight--extra-bold"
                        style={{ fontSize: 36, lineHeight: 1, marginBottom: 12, paddingTop: 60 }}
                    >
                        {title}
                    </p>
                    <p
                        className="color__red font-weight--bold"
                        style={{
                            fontSize: 16,
                            letterSpacing: "0.15em",
                            lineHeight: 1.5,
                            marginBottom: marginBottom,
                            textTransform: "uppercase"
                        }}
                    >
                        {subhead}
                    </p>
                    <p className="color__grey--8 font-weight--medium" style={{ fontSize: contentFontSize, lineHeight: 1.5 }}>{content}</p>
                </div>
            </div>
        );
    }
}
