import { observable, action } from "mobx";
import { Theme } from "../renderer/Themes/Theme";
import { LightTheme } from "../renderer/Themes/LightTheme";
import { DarkTheme } from "../renderer/Themes/DarkTheme";

class ThemeService {
    @observable
    theme: Theme = LightTheme;

    @action
    changeTheme = (theme: keyof typeof themes) => {
        if (themes[theme] && this.theme !== themes[theme]) {
            this.theme = themes[theme];
        }
    }
}

export const themeService = new ThemeService;

export const themes = {
    "light": LightTheme,
    "dark": DarkTheme,
}
