import React from 'react';
import { observer } from "mobx-react";
import { viewportService } from "../../services/ViewportService";
import { trColor } from "../Themes/Chainlink";

export const HeadingContent = observer((props: {
    heading: string;
    content?: string;
    color?: keyof typeof trColor;
    children?: React.ReactNode;
}) => {
    const { heading, content, color = "white", children } = props;
    const { width } = viewportService;
    const headingFontSize = width < 660 ? 24 : 36;
    const contentfontSize = width < 660 ? 18 : 30;
    const marginTop = width < 660 ? 20 : 40;
    return (
        <div style={{ color: trColor[color] }}>
            <p
                className="font-weight--extra-bold"
                style={{ fontSize: headingFontSize, lineHeight: 1.2 }}
            >
                {heading}
            </p>
            {(children || content) && (
                <p style={{ marginTop: marginTop, fontSize: contentfontSize, lineHeight: 1.4 }}>
                    {content ? content : children}
                </p>
            )}
        </div>
    );
});
