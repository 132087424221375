import React from 'react';
import { observer } from "mobx-react";
import { classnames } from "../../helpers/classnames";
import { viewportService } from "../../services/ViewportService";
import { trColor } from "../Themes/Chainlink";
import { HorizontalRule } from "../Components/HorizontalRule";

export const ListContent = observer((props: { list: string[], color?: keyof typeof trColor }) => {
    const { list, color = "white" } = props;
    const { width } = viewportService;
    return (
        <div
            className="text-align--left"
            style={{
                color: trColor[color],
                fontSize: width < 660 ? 18 : 30,
                lineHeight: 1.4,
                paddingTop: width < 660 ? 20 : 40,
            }}
        >
            {list.map((item, i) =>
                <span
                    key={`${item}-${i}`}
                    className={classnames(
                        "display--flex", {
                            [`margin-bottom--${width < 660 ? 12 : 20}`] : i !== list.length - 1,
                        }
                    )}
                >
                    <HorizontalRule className="flex--auto margin-right--12" style={{ marginTop: width < 660 ? 11 : 19 }} />
                    {item}
                </span>
            )}
        </div>
    );
});
