declare type ClassValue = string | number | ClassDictionary | ClassArray | undefined | null | false;

interface ClassDictionary {
    [id: string]: boolean | undefined | null;
}

interface ClassArray extends Array<ClassValue> {}

const hasOwn = {}.hasOwnProperty;

function isClassDictionary(c: ClassValue): c is ClassDictionary {
    return typeof c === "object";
}

function isClassArray(c: ClassValue): c is ClassArray {
    return Array.isArray(c) && !!c.length;
}

export function classnames(...classes: ClassValue[]): string {
    var activeClasses = [];
    for (const c of classes) {
        if (!c) continue;

        if (typeof c === "string" || typeof c === "number") {
            activeClasses.push(c);
        } else if (isClassArray(c)) {
            var inner = classnames.apply(null, c);
            if (inner) {
                classes.push(c);
            }
        } else if (isClassDictionary(c)) {
            for (const key in c) {
                if (hasOwn.call(c, key) && c[key]) {
                    activeClasses.push(key);
                }
            }
        }
    }
    return activeClasses.join(" ");
}

export default classnames;
