import React, { Component, CSSProperties } from "react";
import { observer } from "mobx-react";
import isTouchDevice from "is-touch-device";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { classnames } from "../../../helpers/classnames";
import { Spring } from "react-spring/renderprops";
import { SlideContext, SlideContextType } from "./SlideContext";
import { trColor } from "../../Themes/Chainlink";
import { themes } from "../../../services/ThemeService";
import { sliderService } from "../../../services/SliderService";
import { viewportService } from "../../../services/ViewportService";
import { easeQuadIn } from "d3-ease";

interface IProps {
    theme: keyof typeof themes;
    children?: React.ReactNode;
    backgroundColor?: keyof typeof trColor;
    backgroundImage?: string;
    backgroundStyle?: CSSProperties;
    className?: string;
    alignment?: "flex-start" | "center";
}

@observer
export class Slide extends Component<IProps> {
    static contextType = SlideContext;
    context!: SlideContextType;
    slideRef = React.createRef<HTMLDivElement>();
    scrollBox = React.createRef<HTMLDivElement>();

    componentDidMount() {
        sliderService.addSlide(this.props.theme);

        if (isTouchDevice()) {
            disableBodyScroll(this.scrollBox.current);
        } else {
            enableBodyScroll(this.scrollBox.current);
        }
    }

    render() {
        const { index, currentSlide } = this.context;
        const {
            children,
            backgroundColor,
            backgroundImage,
            backgroundStyle,
            className,
            alignment = "center",
        } = this.props;
        const isOverflow = sliderService.isOverflow(
            this.slideRef,
            viewportService.height
        );
        return (
            <div
                ref={this.scrollBox}
                className={classnames(
                    "slide overflow--auto position--relative padding-horizontal--32 sm__padding-horizontal--60 height--100 display--flex justify-content--center",
                    className,
                    {
                        ["align-items--flex-start"]: isOverflow,
                        ["align-items--" + alignment]: !isOverflow,
                    }
                )}
                style={Object.assign(
                    {
                        backgroundColor: backgroundColor
                            ? trColor[backgroundColor]
                            : trColor.grey1,
                    },
                    isOverflow && {
                        paddingTop: 120,
                        paddingBottom: 120,
                    }
                )}
            >
                {typeof backgroundImage !== "undefined" && (
                    <div
                        className="position--absolute imageFilter"
                        style={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            background: `url(${backgroundImage}) center no-repeat`,
                            backgroundSize: "cover",
                            zIndex: 1,
                            ...backgroundStyle,
                        }}
                    />
                )}
                {children && (
                    <Spring
                        config={{
                            duration: 500,
                            delay: 500,
                            easing: easeQuadIn,
                        }}
                        from={{ opacity: 0 }}
                        to={{ opacity: index === currentSlide ? 1 : 0 }}
                    >
                        {(props) => (
                            <div
                                ref={this.slideRef}
                                className="position--relative"
                                style={{ zIndex: 2, ...props }}
                            >
                                {children}
                            </div>
                        )}
                    </Spring>
                )}
            </div>
        );
    }
}
