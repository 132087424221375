import React, { Component, CSSProperties } from 'react';
import { Transition } from "react-spring/renderprops";
import { observer } from "mobx-react";
import { classnames } from "../../helpers/classnames";
import { Theme } from "../Themes/Theme";
import { LightTheme } from "../Themes/LightTheme";
import { themeService } from "../../services/ThemeService";
import { sliderService } from "../../services/SliderService";
import { viewportService } from "../../services/ViewportService";
import { ThemeSpring } from "./ThemeSpring";
import { pages } from "../../PageTransitioner";

export interface TitleProps {
    whiteSpace?: "pre-wrap" | "nowrap";
    onArrowClick: () => void;
    is404?: boolean;
}

@observer
export class Title extends Component<TitleProps> {
    state = {
        title: "",
        number: 0,
    }

    componentDidMount() {
        const page = pages.filter(page => page.to === location.pathname);
        if (page.length > 0) {
            const title = pages.filter(page => page.to === location.pathname)[0].name;
            const number = pages.findIndex(page => page.name === title) + 1;
            this.setState({ title, number });
        }
    }

    render() {
        const { theme } = themeService;
        const { currentSlide, slides } = sliderService;
        const isFirstSlide: boolean = currentSlide === 0;
        const isLastSlide: boolean = currentSlide === slides.length - 1;
        const { title, number } = this.state;
        const { whiteSpace = "nowrap", onArrowClick, is404 } = this.props;
        const nextPage = is404 ? "404" : number + 1 > pages.length ? "fin." : number + 1;
        const { width } = viewportService;
        const fontSize = width < 600 ?
            60 : width < 870 ?
            80 : width < 1024 ?
            120 :
            160;

        return (
            <>
                <Transition
                    items={isFirstSlide && !is404}
                    from={{ transform: "translate3d(-100%, -50%, 0)", opacity: 0 }}
                    enter={{ transform: "translate3d(-50%, -50%, 0)", opacity: 1 }}
                    leave={{ transform: "translate3d(-100%, -50%, 0)", opacity: 0 }}
                    config={{ delay: isFirstSlide ? 500 : 0 }}
                >
                    {(isFirstSlide: boolean) => isFirstSlide && (props =>
                        <>
                            <TitleText
                                theme={LightTheme}
                                style={{
                                    top: "50%",
                                    bottom: "auto",
                                    left: "50%",
                                    fontSize: fontSize,
                                    fontWeight: 900,
                                    whiteSpace: whiteSpace,
                                    lineHeight: 0.8,
                                    ...props
                                }}
                            >
                                {title}
                                <TitleNumber
                                    className="position--absolute"
                                    number={number}
                                    theme={LightTheme}
                                    style={{ opacity: .5 }}
                                    offsetTop
                                />
                            </TitleText>
                            <TitleArrow isBottom theme={LightTheme} style={props} onClick={onArrowClick} />
                        </>
                    )}
                </Transition>
                <Transition
                    items={!isFirstSlide && !isLastSlide}
                    from={{ transform: "translate3d(50%, 0%, 0)", opacity: 0 }}
                    enter={{ transform: "translate3d(0%, 0%, 0)", opacity: 1 }}
                    leave={{ transform: "translate3d(50%, 0%, 0)", opacity: 0 }}
                >
                    {(isNotFirstOrLastSlide: boolean) => isNotFirstOrLastSlide && (props =>
                        <TitleText
                            theme={theme}
                            style={{
                                top: "auto",
                                bottom: 0,
                                right: width < 870 ? -4 : -12,
                                fontSize: width < 870 ? 10 : 12,
                                whiteSpace: "nowrap",
                                ...props
                            }}
                            onClick={onArrowClick}
                        >
                            {title}
                            <TitleNumber className="margin-left--8 position--relative" number={number} theme={theme}>
                                <TitleArrow theme={theme} />
                            </TitleNumber>
                        </TitleText>
                    )}
                </Transition>
                <Transition
                    items={isLastSlide}
                    from={{ transform: "translate3d(-50%, 100%, 0)", opacity: 0 }}
                    enter={{ transform: "translate3d(-50%, 25%, 0)", opacity: 1 }}
                    leave={{ transform: "translate3d(-50%, 100%, 0)", opacity: 0 }}
                >
                    {(isLastSlide: boolean) => isLastSlide && (props =>
                        <TitleText
                            theme={theme}
                            style={{
                                top: "auto",
                                bottom: 0,
                                left: "50%",
                                fontSize: 12,
                                whiteSpace: "nowrap",
                                ...props
                            }}
                            onClick={onArrowClick}
                        >
                            <TitleNumber className="position--relative" number={nextPage} theme={theme}>
                                <TitleArrow theme={theme} />
                            </TitleNumber>
                        </TitleText>
                    )}
                </Transition>
            </>
        );
    }
}

const TitleText = (props: {
    children: React.ReactNode;
    style: CSSProperties;
    theme: Theme;
    onClick?: () => void;
}) => {
    const { children, style, theme, onClick } = props;
    return (
        <ThemeSpring prevDelay={200} nextDelay={100} style={{ color: theme.title.text }}>
            <div
                className="position--fixed"
                style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    letterSpacing: "0.02em",
                    zIndex: 4,
                    transition: "color 200ms ease-out",
                    cursor: onClick ? "pointer" : undefined,
                    ...style,
                }}
                onClick={onClick}
            >
                {children}
            </div>
        </ThemeSpring>
    );
}

const TitleNumber = observer((props: {
    number?: number | string;
    children?: React.ReactNode;
    className: string;
    theme: Theme;
    style?: CSSProperties;
    offsetTop?: boolean;
}) => {
    const { number, children, className, theme, style, offsetTop } = props;
    const { width } = viewportService;
    const fontSize = width < 870 ?
        40 : width < 1024 ?
        60 :
        80;
    const topOffset = fontSize * -.8;
    return (
        <ThemeSpring prevDelay={300} nextDelay={100} style={{ color: theme.title.number, ...style }}>
            <span
                className={className}
                style={{
                    top: offsetTop ? topOffset : 0,
                    left: 0,
                    fontSize: fontSize,
                    fontWeight: 900,
                    textTransform: typeof number !== "number" ? "lowercase" : undefined,
                }}
            >
                {typeof number === "number" ? children : null}
                {typeof number === "number" ? `0${number}.` : number}
            </span>
        </ThemeSpring>
    );
});

const TitleArrow = observer((props: {
    isBottom?: boolean;
    style?: CSSProperties;
    theme: Theme;
    onClick?: () => void;
}) => {
    const { isBottom, theme, style, onClick } = props;
    const { width } = viewportService;
    const fontSize = width < 870 ?
        40 : width < 1024 ?
        60 :
        80;
    return (
        <ThemeSpring prevDelay={300} nextDelay={100} style={{ color: theme.title.arrow }}>
            <span
                className={classnames(
                    "position--fixed icon-arrow-tail-down icon-no-margin font-size--24 transition--default cursor--pointer", {
                        ["position--fixed"]: isBottom,
                        ["position--absolute"]: !isBottom
                    }
                )}
                style={{
                    ...style,
                    bottom: isBottom ? 40 : "100%",
                    left: isBottom ? "50%" : fontSize*.63,
                    transform: "translate3d(-50%, 0, 0)",
                    zIndex: 2
                }}
                onClick={onClick}
            />
        </ThemeSpring>
    );
});
