import { observable } from "mobx";
import { throttle } from "lodash";

const throttleConfig = { leading: true, trailing: true };

class ViewportService {
    @observable
    width: number = window.innerWidth;

    @observable
    height: number = window.innerHeight;

    constructor() {
        window.addEventListener("resize", throttle(this.setViewportSize, 16, throttleConfig), false);
    }

    setViewportSize = () => {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }
}

export const viewportService = new ViewportService;
