import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
import { throttle } from "lodash";
import { themeService } from "../../../services/ThemeService";
import { sliderService } from "../../../services/SliderService";
import { Spring } from "react-spring/renderprops";
import { SlideContext } from "./SlideContext";
import { easeExpInOut } from "d3-ease";
import { ThemeSpring } from "../ThemeSpring";
import { pages } from "../../../PageTransitioner";
import { Title, TitleProps } from "../Title";

const throttleConfig = { leading: true, trailing: true };

interface IProps {
    children: React.ReactNode;
    hideNavigation?: boolean;
    showTitle?: boolean;
    titleProps?: TitleProps;
    is404?: boolean;
}

@observer
export class Slider extends Component<IProps> {
    throttledKeyDown: any;
    throttledWheel: any;
    throttledTouchStart: any;
    throttledTouchEnd: any;
    node: any = null;

    constructor(props: any) {
        super(props);
        sliderService.resetSlides();
        this.throttledKeyDown = throttle(this.handleKeyDown, 16, throttleConfig);
        this.throttledWheel = throttle(this.handleWheel, 16, throttleConfig);
        this.throttledTouchStart = throttle(this.handleTouchStart, 16, throttleConfig);
        this.throttledTouchEnd = throttle(this.handleTouchEnd, 16, throttleConfig);
    }

    state = {
        hideNavigation: true,
        currentSlide: 0,
        totalSlides: React.Children.count(this.props.children),
        animation: false,
        isOverflow: false,
        touchStart: 0,
        touchEnd: 0,
    }

    handleSlideChange = (currentSlide: number) => {
        if (this.state.animation) return;
        const animation = true;

        if (this.node instanceof HTMLElement) {
            const childWrapper = this.node.querySelector('#slideWrapper');
            if (childWrapper) {
                const child = childWrapper.childNodes[this.state.currentSlide];
                //@ts-ignore
                if (child.scrollHeight - child.scrollTop - 30 <= child.clientHeight || child.scrollHeight - child.scrollTop + 30 >= child.scrollHeight) this.setState({ isOverflow: false });
                else this.setState({ isOverflow: true });
            }
        }

        if (!this.state.isOverflow) {
            this.setState({ currentSlide, animation, touchStart: 0, touchEnd: 0 });
            sliderService.updateCurrentSlide(currentSlide);
            themeService.changeTheme(sliderService.slides[currentSlide]);
        }
    }

    resetAnimationState = () => {
        this.setState({ animation: false });
    }

    componentDidMount() {
        this.node = ReactDOM.findDOMNode(this);
        this.handleSlideChange(0);
        window.addEventListener("touchstart", this.throttledTouchStart);
        window.addEventListener("touchend", this.throttledTouchEnd);
        window.addEventListener("keydown", this.throttledKeyDown);
        window.addEventListener("wheel", this.throttledWheel);
        setTimeout(() => {
            this.setState({ hideNavigation: false });
        }, 750);
    }

    componentWillUnmount() {
        window.removeEventListener("touchstart", this.throttledTouchStart);
        window.removeEventListener("touchend", this.throttledTouchEnd);
        window.removeEventListener("keydown", this.throttledKeyDown);
        window.removeEventListener("wheel", this.throttledWheel);
    }

    handleKeyDown = (e: KeyboardEvent) => {
        switch(e.keyCode) {
            case 39:
            case 40:
                this.nextSlideChange();
                break;
            case 37:
            case 38:
                this.prevSlideChange();
                break;
        }
    }

    handleTouchStart = (e: TouchEvent) => {
        this.setState({ touchStart: e.touches[0].clientY });
    }

    handleTouchEnd = (e: TouchEvent) => {
        const { touchStart } = this.state;
        const touchEnd = e.changedTouches[0].clientY;
        this.setState({ touchEnd });
        if (touchStart > touchEnd + 5) this.nextSlideChange();
        else if (touchStart < touchEnd - 5) this.prevSlideChange();
    }

    handleWheel = (e: WheelEvent) => {
        if (Math.abs(e.deltaY) < 20)
            return;
        else if (e.deltaY > 0)
            this.nextSlideChange();
        else
            this.prevSlideChange();
    }

    prevSlideChange = () => {
        const decrement = this.state.currentSlide - 1;
        if (decrement >= 0) this.handleSlideChange(decrement);
    }

    nextSlideChange = () => {
        if (!this.state.animation) {
            const increment = this.state.currentSlide + 1;
            const pageIndex = pages.findIndex(page => page.to === location.pathname);
            let nextPage = pageIndex + 1;
            if (nextPage > pages.length - 1) nextPage = -1;
            if (increment < this.state.totalSlides) this.handleSlideChange(increment);
            else {
                this.setState({ animation: true });
                const to = nextPage > 0 ? pages[nextPage].to : location.pathname === "/" ? pages[0].to : "/";
                //@ts-ignore
                this.props.history.push(to);
            }
        }
    }

    render() {
        const { currentSlide } = this.state;
        const { hideNavigation, showTitle, titleProps, children, is404 } = this.props;
        return (
            <>
                {showTitle && <Title is404={is404} onArrowClick={this.nextSlideChange} {...titleProps} />}
                <div className="position--relative width--100 height--100 background-color__white">
                    {!hideNavigation && (
                        <div
                            style={{
                                position: "fixed",
                                top: 0, right: 0, bottom: 0, left: 0,
                                opacity: this.state.hideNavigation ? 0 : 1,
                                transition: "all 500ms ease-out",
                                pointerEvents: "none",
                                zIndex: 9999,
                            }}
                        >
                            {this.renderNavigation()}
                        </div>
                    )}
                    <Spring
                        config={{ duration: 750, easing: easeExpInOut }}
                        to={{ transform: `translate3d(0, -${100 * currentSlide}%, 0)` }}
                        onRest={this.resetAnimationState}
                    >
                        {props => (
                            <div
                                id="slideWrapper"
                                className="height--100 width--100"
                                style={{
                                    transform: props.transform
                                }}
                            >
                                {this.renderChildren()}
                            </div>
                        )}
                    </Spring>
                </div>
            </>
        );
    }

    renderChildren = () => {
        const { children } = this.props;
        const currentSlide = this.state.currentSlide;
        return React.Children.map(children, (child, index) => (
            <SlideContext.Provider value={{ index, currentSlide }}>{child}</SlideContext.Provider>
        ));
    };

    renderNavigation = () => {
        const { children } = this.props;
        const { currentSlide } = this.state;
        const { active, inactive } = themeService.theme.navigation;
        return (
            <div
                className="position--absolute display--none sm__display--flex flex-direction--column"
                style={{ left: 0, top: "50%", transform: "translate3d(0, -50%, 0)", pointerEvents: "all" }}
            >
                {React.Children.map(children, (child, index) => (
                    <SliderTick
                        key={`slide-tick-${index}`}
                        isActive={currentSlide === index}
                        color={currentSlide === index ? active : inactive}
                        onClick={() => this.handleSlideChange(index)}
                    />
                ))}
            </div>
        );
    }
}

class SliderTick extends Component<{
    isActive?: boolean;
    color: string;
    onClick: () => void;
}> {
    state = {
        isHovered: false,
    }

    handleHover = (isHovered: boolean) => {
        this.setState({ isHovered });
    }

    render() {
        const { isActive, color, onClick } = this.props;
        const { isHovered } = this.state;
        const delay = isHovered ? 0 : 200;
        const width = isActive ? 32 : isHovered ? 24 : 12;
        return (
            <div
                className="padding-vertical--4 cursor--pointer"
                onClick={onClick}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}
            >
                <ThemeSpring
                    prevDelay={delay}
                    nextDelay={delay}
                    style={{
                        width: width,
                        backgroundColor: color
                    }}
                >
                    <div style={{ height: 2 }} />
                </ThemeSpring>
            </div>
        );
    }
}
