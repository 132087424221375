import React, { Component } from 'react';
import { observer } from "mobx-react";
import { classnames } from "../../helpers/classnames";
import { viewportService } from "../../services/ViewportService";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { Link } from "react-router-dom";
import { pages } from "../../PageTransitioner";

export const TableOfContentsView = observer((renderProps: any) => {
    const { width } = viewportService;
    return (
        <Slider hideNavigation {...renderProps}>
            <Slide theme="light" backgroundColor="grey2">
                {pages.map((page, i) =>
                    <TableLink
                        key={i}
                        number={i + 1}
                        name={width < 934 && page.shortName ? page.shortName : page.name}
                        to={page.to}
                    />
                )}
            </Slide>
        </Slider>
    );
});

interface TableLinkProps {
    number: number;
    name: string;
    to: string;
}

@observer
class TableLink extends Component<TableLinkProps> {
    state = {
        isHovered: false
    }

    handleHover = (isHovered: boolean) => {
        this.setState({ isHovered });
    }

    render() {
        const { number, name, to } = this.props;
        const { isHovered } = this.state;
        const { width } = viewportService;
        const fontSize = width < 600 ? 40 : 80;
        return (
            <Link
                className={classnames(
                    "cursor--pointer display--flex align-items--baseline font-weight--black margin-bottom--20 transition--default", {
                        ["color__white"]: !isHovered,
                        ["color__grey--11"]: isHovered
                    }
                )}
                style={{ textDecoration: "none" }}
                to={to}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}
            >
                <span className="margin-right--16" style={{ fontSize: 40, opacity: .5 }}>0{number}.</span>
                <span style={{ fontSize: fontSize, textTransform: "uppercase", lineHeight: 1 }}>{name}</span>
            </Link>
        );
    }
}
