import React from "react";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { ValuesVennDiagram } from "../Components/ValuesVennDiagram/ValuesVennDiagram";
import { GeneralContent } from "./GeneralContent";
import { ValueContent } from "./ValueContent";
import { HorizontalRule } from "../Components/HorizontalRule";
import titleImage from "../../assets/images/values/values-title.jpg";
import boardroomImage from "../../assets/images/values/boardroom-background.jpg";
import thumbsDownIcon from "../../assets/images/values/thumbs-down-icon.svg";
import jockoImage from "../../assets/images/values/jocko-headshot.jpg";
import kimImage from "../../assets/images/values/kim-headshot.jpg";
import radicalCandorChart from "../../assets/images/values/radical-candor-chart.png";

export const CompanyValuesView = (renderProps: any) => (
    <>
        <ValuesVennDiagram
            values={[
                "Constructive Debate",
                "Extreme Ownership",
                "Radical Candor",
                "Constant Improvement",
            ]}
            endSlide={14}
        />
        <Slider
            showTitle
            titleProps={{ whiteSpace: "pre-wrap" }}
            {...renderProps}
        >
            <Slide
                theme="light"
                backgroundColor="red2"
                backgroundImage={titleImage}
            />
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={844}>
                    While we all have individual priorities and areas of
                    expertise, we are{" "}
                    <span className="font-weight--extra-bold">one team</span>{" "}
                    working together to accomplish a shared goal &mdash; making
                    cyclists faster.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent
                    theme="dark"
                    maxWidth={830}
                    text="Our company values represent who we strive to be and the assembled group we want to work with."
                />
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={830}>
                    Don’t let the term{" "}
                    <span className="font-style--italic">company values</span>{" "}
                    fool you. They aren’t just an exercise in corporate mumbo
                    jumbo.
                </GeneralContent>
            </Slide>
            <Slide
                theme="light"
                backgroundColor="black"
                backgroundImage={boardroomImage}
            >
                <GeneralContent theme="light">
                    <img
                        style={{ width: "75%" }}
                        src={thumbsDownIcon}
                        alt="Thumbs Down"
                    />
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={786}>
                    These values{" "}
                    <span className="font-weight--extra-bold">
                        shape our culture
                    </span>
                    ,{" "}
                    <span className="font-weight--extra-bold">
                        polish the team
                    </span>
                    , and{" "}
                    <span className="font-weight--extra-bold">
                        allow us to be better
                    </span>{" "}
                    every single day.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={786} />
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <ValueContent
                    title="Constructive Debate"
                    subhead="Have strong opinions held loosely."
                    content="We need to disagree, debate, and align. Have opinions, bring new ideas, and challenge others. The key is going to that uncomfortable place where you can passionately discuss ideas without getting personal. New information can change your viewpoint, and that's a delightful experience."
                />
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <ValueContent
                    title="Extreme Ownership"
                    subhead="Ownership is a mindset, an attitude."
                    content={`Mistakes happen. What's important is that we own our mistakes and figure out what we can do better next time. Having an "it's always my fault" mindset allows you to start thinking of solutions rather than passing blame.`}
                    extendPadding
                />
                <Speaker
                    title="Extreme Ownership"
                    speaker="Jocko"
                    imageSrc={jockoImage}
                    links={[
                        {
                            name: "Watch Jocko's talk",
                            url: "https://www.youtube.com/watch?v=ljqra3BcqWM",
                        },
                        {
                            name: "Read the book",
                            url:
                                "https://www.amazon.com/Extreme-Ownership-U-S-Navy-SEALs-ebook/dp/B00VE4Y0Z2",
                        },
                    ]}
                />
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <ValueContent
                    title="Radical Candor"
                    subhead="Care personally and challenge directly."
                    content="Being honest and caring in our communication is a key driver of growth. We need to be able to challenge each other while also caring about that person. Don't be a jerk, but don't be dishonest to spare someone's feelings."
                    extendPadding
                />
                <Speaker
                    title="Radical Candor"
                    speaker="Kim"
                    imageSrc={kimImage}
                    links={[
                        {
                            name: "Watch Kim's talk",
                            url: "https://www.youtube.com/watch?v=yj9GLeNCgm4",
                        },
                        {
                            name: "Read the book",
                            url:
                                "https://www.amazon.com/Radical-Candor-Kim-Scott/dp/B01KTIEFEE",
                        },
                    ]}
                />
            </Slide>
            <Slide
                theme="dark"
                backgroundColor="white"
                backgroundImage={radicalCandorChart}
                backgroundStyle={{
                    backgroundSize: "contain",
                    top: 40,
                    bottom: 40,
                }}
            />
            <Slide theme="dark" backgroundColor="white">
                <ValueContent
                    title="Constant Improvement"
                    subhead="What can we learn today to be better tomorrow?"
                    content="Nothing we do today is perfect. Some of it might not even be good. Everyone should have the courage to voice their opinion when something can be improved. By continually looking at how to improve that extra 1%, our efforts compound over time, and we become a stronger company."
                />
            </Slide>
            <Slide theme="dark" backgroundColor="white" />
            <Slide theme="light" backgroundColor="white" />
        </Slider>
    </>
);

type Link = {
    name: string;
    url: string;
};

const Speaker = (props: {
    title: string;
    imageSrc: string;
    speaker: string;
    links: Link[];
}) => {
    const { title, imageSrc, speaker, links } = props;
    return (
        <div
            className="position--absolute display--flex flex-direction--column-reverse sm__flex-direction--row sm__align-items--center"
            style={{ bottom: -120 }}
        >
            <img src={imageSrc} alt={speaker} />
            <div className="sm__margin-left--12">
                <p
                    className="display--flex align-items--center color__grey--7 font-weight--bold"
                    style={{ fontSize: 18 }}
                >
                    <HorizontalRule
                        color="grey10"
                        length={24}
                        className="margin-right--12"
                    />
                    {title}
                </p>
                {links.map((link, i) => (
                    <p
                        key={`${link.name}-${i}`}
                        className="margin-top--8"
                        style={{ fontSize: 14, marginLeft: 36 }}
                    >
                        <a
                            href={link.url}
                            className="link--underline"
                            target="_blank"
                        >
                            {link.name}
                        </a>
                    </p>
                ))}
            </div>
        </div>
    );
};
