import { observable, action } from "mobx";

class LoginService {
    constructor() {
        if (this.getCookie("LoggedIn") === "true") {
            this.loggedIn = true;
        }
    }

    @observable
    loggedIn: boolean = false;

    @observable
    error: boolean = false;

    private password = "bettertogether";

    @action
    submitPassword = (password: string) => {
        if (password === this.password) {
            this.loggedIn = true;
            this.error = false;
            this.setCookie("LoggedIn", "true", 365);
        } else {
            this.error = true;
        }
    }

    @action
    setCookie = (cname: string, cvalue: string, exdays: number) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    @action
    getCookie = (cname: string) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}

export const loginService = new LoginService;
