import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { HorizontalRule } from "../Components/HorizontalRule";
import { GeneralContent } from "./GeneralContent";
import flatTireImage from "../../assets/images/login/flat-tire.svg";

export const ErrorView = (renderProps: any) => {
    const [ isHovered, setHover ] = useState<boolean>();
    return (
        <Slider is404 showTitle hideNavigation {...renderProps}>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={628}>
                    <div className="display--flex flex-direction--column align-items--center">
                        <img src={flatTireImage}  style={{ marginBottom: 28, width: "75%", maxWidth: 184 }} />
                        Whoops… looks like we have a flat. We’ll get that patched up.
                        <Link
                            to="/"
                            className="position--relative color__grey--9 margin-top--60"
                            style={{ fontSize: 18, fontWeight: 500, textDecoration: "none" }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            Let’s get back to the
                            <span
                                className="margin-left--4 display--inline-block border-color__red"
                                style={{ borderBottom: "1px solid transparent" }}
                            >
                                Culture Manual
                            </span>
                            <HorizontalRule
                                length={isHovered ? "100%" : 121}
                                color="red"
                                className="display--none sm__display--block position--absolute transition--default"
                                style={{ bottom: 0, right: 0 }}
                            />
                        </Link>
                    </div>
                </GeneralContent>
            </Slide>
        </Slider>
    );
}
