import React, { Component } from 'react';
import { NavLink as ReactNavLink, NavLinkProps, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { themeService } from "../../services/ThemeService";
import { Theme } from "../Themes/Theme";
import { loginService } from "../../services/LoginService";
import { viewportService } from "../../services/ViewportService";
import { Spring, Transition } from "react-spring/renderprops";
import { ThemeSpring } from "./ThemeSpring";
import { pages } from "../../PageTransitioner";
import { findDOMNode } from "react-dom";
import { throttle } from "lodash";
import { classnames } from "../../helpers/classnames";

const throttleConfig = { leading: true, trailing: true };

@observer
export class Header extends Component {
    activeIndicator = React.createRef<HTMLDivElement>();
    activeNode: Element | null = null;
    handleActiveNode = (node: Element | null) => {
        if (this.activeNode !== node) {
            this.activeNode = node;
            this.handleActive();
        }
    }

    handleActive = () => {
        const indicator = this.activeIndicator.current;
        if (!indicator) return;
        if (!this.activeNode) {
            indicator.style.opacity = "0";
            return;
        } else {
            window.requestAnimationFrame(() => {
                if (this.activeNode) {
                    const navLinkRect = this.activeNode.getBoundingClientRect();
                    indicator.style.opacity = "1";
                    indicator.style.width = navLinkRect.width + "px";
                    indicator.style.left = navLinkRect.left + "px";
                }
            });
        }
    }

    componentDidMount() {
        setTimeout(() => this.handleActive(), 200);
        window.addEventListener("resize", throttle(this.handleActive, 16, throttleConfig));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.handleActive, 16, throttleConfig));
    }

    render() {
        const isLoggedIn = loginService.loggedIn;
        const { theme } = themeService;
        const { width } = viewportService;
        const fromStyle = { transform: "translate3d(0, -100%, 0)" };
        const toStyle = { transform: "translate3d(0, 0, 0)" };
        return (
            <Spring from={fromStyle} to={toStyle}>
                {(props: any) =>
                    <div
                        className="position--fixed width--100 padding-vertical--16 sm__padding-vertical--20 padding-horizontal--12 sm__padding-horizontal--24 display--flex justify-content--space-between align-items--center"
                        style={{ top: 0, left: 0, zIndex: 9999, ...props }}
                    >
                        <Link className="cursor--pointer display--flex align-items--center" to="/" style={{ textDecoration: "none" }}>
                            <ThemeSpring style={{ color: theme.header.mark }}>
                                <span
                                    className="icon-no-margin icon-tr-new margin-right--12 display--flex align-items--center transition--default"
                                    style={{ fontSize: 50, height: 28 }}
                                />
                            </ThemeSpring>
                            <ThemeSpring style={{ color: theme.header.typeface }}>
                                <span className="font-size--15 font-weight--black transition--default">CultureManual.</span>
                            </ThemeSpring>
                        </Link>
                        {width < 820 ? (
                            <Link
                                className={classnames(
                                    "cursor--pointer", {
                                        ["display--none"]: location.pathname === "/" || !isLoggedIn
                                    }
                                )}
                                style={{ fontSize: 28, textDecoration: "none" }}
                                to="/"
                            >
                                <ThemeSpring style={{ color: theme.header.typeface }}>
                                    <span className="icon-no-margin icon-hamburger" />
                                </ThemeSpring>
                            </Link>
                        ) : (
                            <>
                                <ThemeSpring style={{ borderTopColor: theme.header.indicator }}>
                                    <div
                                        ref={this.activeIndicator}
                                        className="position--fixed"
                                        style={{
                                            borderTop: `4px solid transparent`,
                                            transition: "all 500ms ease-out",
                                            top: 0,
                                        }}
                                    />
                                </ThemeSpring>
                                <Transition items={isLoggedIn} from={fromStyle} enter={toStyle} leave={toStyle}>
                                    {(isLoggedIn: boolean) => isLoggedIn && ((props: any) =>
                                        <div className="position--relative" style={props}>
                                            {pages.map((page, i) =>
                                                <NavLink
                                                    key={i}
                                                    number={i + 1}
                                                    text={page.shortName ? page.shortName : page.name}
                                                    to={page.to}
                                                    theme={theme}
                                                    handleActive={this.handleActiveNode}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Transition>
                            </>
                        )}
                    </div>
                }
            </Spring>
        );
    }
}

interface INavLinkProps extends NavLinkProps {
    number: number;
    text: string;
    theme: Theme;
    handleActive: (node: Element | null) => void;
}

export class NavLink extends Component<INavLinkProps> {
    state = {
        isHovered: false,
    }

    handleMatch = (match: any, location: any) => {
        if (match) {
            const node = findDOMNode(this);
            if (node === null || node instanceof Element) this.props.handleActive(node);
        } else if (pages.filter(page => page.to === location.pathname).length === 0) {
            this.props.handleActive(null);
        }
        return true;
    }

    handleHover = (isHovered: boolean) => {
        this.setState({ isHovered });
    }

    render() {
        const { number, text, theme, handleActive, ...rest } = this.props;
        const { isHovered } = this.state;
        return (
            <ReactNavLink
                {...rest}
                className="font-size--14 margin-left--32"
                style={{ letterSpacing: "0.1em", textTransform: "uppercase", textDecoration: "none" }}
                isActive={this.handleMatch}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}
            >
                <ThemeSpring style={{ color: theme.header.navLink.number }}>
                    <span className="font-weight--semi-bold margin-right--4 transition--default">0{number}.</span>
                </ThemeSpring>
                <ThemeSpring
                    nextDelay={isHovered ? 0 : undefined}
                    prevDelay={isHovered ? 0 : undefined}
                    style={{ color: isHovered ? theme.header.navLink.text.hover : theme.header.navLink.text.default }}
                >
                    <span className="font-weight--bold transition--default">{text}</span>
                </ThemeSpring>
            </ReactNavLink>
        );
    }
}
