import React from 'react';
import { observer } from "mobx-react";
import { viewportService } from "../../services/ViewportService";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { GeneralContent } from "./GeneralContent";
import { ListContent } from "./ListContent";
import titleImage from "../../assets/images/audience/audience-title-v2.jpg";
import trAthleteImage from "../../assets/images/audience/tr-athlete-background-v2.jpg";
import nonTRAthleteImage from "../../assets/images/audience/non-tr-athlete-background.jpg";
import trLogo from "../../assets/images/audience/trainerroad-wordmark-white.svg";
import genderImage from "../../assets/images/audience/gender-graphic-v2.svg";
import ageImage from "../../assets/images/audience/age-graphic.svg";
import globalAmerica from "../../assets/images/audience/global-america.svg";
import globalUnitedKingdom from "../../assets/images/audience/global-united-kingdom.svg";
import globalAustralia from "../../assets/images/audience/global-australia.svg";
import globalCanada from "../../assets/images/audience/global-canada.svg";

export const AudienceView = observer((renderProps: any) => {
    const { width } = viewportService;
    return (
        <Slider showTitle {...renderProps}>
            <Slide theme="light" backgroundColor="black" backgroundImage={titleImage} />
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={782}>
                    We provide a system for athletes who believe that <span className="font-weight--extra-bold">science, data, structure, and consistency</span> coupled with <span className="font-weight--extra-bold">hard work and dedication</span> will make them faster.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={1000}>
                    <p className="font-weight--extra-bold margin-bottom--32 sm__margin-bottom--60">Gender</p>
                    <img className="width--100" src={genderImage} alt="Male 84% – Female 16%" />
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={1000}>
                    <p className="font-weight--extra-bold margin-bottom--32 sm__margin-bottom--60">Age</p>
                    <img className="width--100" src={ageImage} alt="Median age 40-45" />
                    <p className="margin-top--32 sm__margin-top--60 color__grey--9" style={{ fontSize: 18 }}>[ Median athlete age is 40-45 ]</p>
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={782}>
                    With over <span className="font-weight--extra-bold">127 million workouts</span> completed in <span className="font-weight--extra-bold">150+ countries</span>, approximately 64% of all TrainerRoad athletes are international.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={1000}>
                    <p className="font-weight--extra-bold margin-bottom--32 sm__margin-bottom--60">Top Locations</p>
                    <div className="display--flex justify-content--space-between flex-wrap--wrap" style={{ width: width > 800 ? 740 : undefined }}>
                        <Country image={globalAmerica} percent={46.5} country="United States" />
                        <Country image={globalUnitedKingdom} percent={14.9} country="United Kingdom" />
                        <Country image={globalCanada} percent={7.2} country="Canada" />
                        <Country image={globalAustralia} percent={5.9} country="Australia" />
                    </div>
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={782}>
                    Improving performance through serious and committed training is not easy. TrainerRoad <span className="font-weight--extra-bold">isn’t for everyone</span> and <span className="font-weight--extra-bold">that’s okay</span>.
                </GeneralContent>
            </Slide>
            <Slide theme="light" backgroundColor="black" backgroundImage={trAthleteImage} backgroundStyle={{ backgroundPosition: "left center" }}>
                <GeneralContent theme="light" maxWidth={900}>
                    <TrainerRoadAthletes />
                    <ListContent
                        list={[
                            "Want to get faster, improve themselves, and their performance",
                            "Want or already own a power meter",
                            "Ride performance bikes, clip in, and wear lycra",
                            "Ride with a training purpose and are not afraid of hard work"
                        ]}
                    />
                </GeneralContent>
            </Slide>
            <Slide theme="light" backgroundColor="black" backgroundImage={nonTRAthleteImage}>
                <GeneralContent theme="light" maxWidth={900}>
                    <TrainerRoadAthletes strikethrough />
                    <ListContent
                        list={[
                            "Primarily ride to reduce stress and enjoy the outdoors",
                            "Don’t know what a power meter is",
                            "Ride cruisers, ride fixies, and have flat pedals",
                            "Ride mainly for transportation, or fun, without a training purpose"
                        ]}
                    />
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={782}>
                    We don’t focus on <span className="font-weight--extra-bold">changing the mindset</span> of the non-TrainerRoad athletes. We focus on being the <span className="font-weight--extra-bold">best training solution for any athlete</span> ready to take the next step.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={782}>
                    <p>Let's continue building something great.</p>
                </GeneralContent>
            </Slide>
        </Slider>
    );
});

const TrainerRoadAthletes = observer((props: { strikethrough?: boolean }) => {
    const { width } = viewportService;
    const isMobile = width < 660;
    const logoSize = isMobile ? 30 : 60;
    const height = isMobile ? 4 : 8;
    const marginTop = isMobile ? 3 : 4;
    const marginLeft = isMobile ? 8 : 12;
    return (
        <div className="display--flex align-items--flex-start align-items--center">
            <div className="display--flex align-items--center position--relative">
                {props.strikethrough && (
                    <div
                        className="position--absolute background-color__red width--100"
                        style={{
                            top: "50%", left: 0,
                            height: height,
                            transform: "translate3d(0, -50%, 0)"
                        }}
                    />
                )}
                <img style={{ height: logoSize*.7 }} src={trLogo} alt="TrainerRoad" />
            </div>
            <span style={{ marginTop: marginTop, marginLeft: marginLeft, fontSize: logoSize }}>Athletes</span>
        </div>
    );
});

const Country = observer((props: { image: string; percent: number; country: string; }) => {
    const { width } = viewportService;
    const { image, percent, country } = props;
    return (
        <div
            className={width < 800 ? "flex--auto" : undefined}
            style={width < 800 ?
                { width: width < 500 ? "100%" : "50%", marginBottom: 32 } :
                undefined}
        >
            <img src={image} className="margin-bottom--20" />
            <p className="font-weight--medium color__grey--4" style={{ fontSize: 30 }}>{percent}%</p>
            <p className="font-weight--medium color__grey--9" style={{ fontSize: 18, textTransform: "uppercase" }}>{country}</p>
        </div>
    );
});
