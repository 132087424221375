import React from "react";
import { observer } from "mobx-react";
import { Transition, animated } from "react-spring/renderprops";
import { viewportService } from "../../services/ViewportService";
import portraitMode from "../../assets/images/login/portrait-mode.svg";

export const SwitchToPortrait = observer(() => {
    const { width, height } = viewportService;
    const show = width > height && width <= 896 && height <= 414;
    return (
        <Transition
            items={show}
            from={{ transform: "translate3d(0, 100%, 0)" }}
            enter={{ transform: "translate3d(0, 0, 0)" }}
            leave={{ transform: "translate3d(0, -100%, 0)" }}
        >
            {show => show && (props =>
                <animated.div
                    className="display--flex align-items--center justify-content--center position--fixed background-color__white"
                    style={{ ...props, top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999 }}
                >
                    <p
                        className="display--flex align-items--center padding-horizontal--32 color__grey--4"
                        style={{ fontSize: 24, fontWeight: 500, lineHeight: 1.2 }}
                    >
                        For best experience, view in portrait mode.
                        <img src={portraitMode} className="margin-left--12" />
                    </p>
                </animated.div>
            )}
        </Transition>
    );
});
