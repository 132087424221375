import React, { Component, RefObject, createRef } from 'react';
import { observer } from "mobx-react";
import { Transition, animated } from "react-spring/renderprops";
import { classnames } from "../../../helpers/classnames";
import "./Login.scss";
import { loginService } from "../../../services/LoginService";
import { themeService } from "../../../services/ThemeService";
import backgroundImage from "../../../assets/images/login/login-background.jpg";

@observer
export class Login extends Component {
    passwordRef: RefObject<HTMLInputElement> = createRef();

    componentDidMount() {
        themeService.changeTheme("dark");
    }

    handleSubmit = () => {
        if (this.passwordRef.current !== null) {
            loginService.submitPassword(this.passwordRef.current.value);
        }
    }

    handleForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.handleSubmit();
    }

    render() {
        const show = !loginService.loggedIn;
        return (
            <div
                className="position--fixed display--flex align-items--center justify-content--center background-color__white transition--default"
                style={{
                    background: `url(${backgroundImage}) center no-repeat`,
                    backgroundSize: "cover",
                    top: 0, right: 0, bottom: 0, left: 0,
                    opacity: show ? 1 : 0,
                    pointerEvents: show ? "all" : "none"
                }}
            >
                <form
                    className={classnames("position--relative display--flex", { ["error"]: loginService.error })}
                    onSubmit={this.handleForm}
                >
                    <Transition
                        items={loginService.error}
                        from={{ transform: "translate3d(0, -40%, 0)", opacity: 0 }}
                        enter={{ transform: "translate3d(0, -90%, 0)", opacity: 1 }}
                        leave={{ transform: "translate3d(0, 140%, 0)", opacity: 0 }}
                    >
                        {showError => showError && (props =>
                            <animated.div className="login__error" style={props}>
                                <span className="icon-no-margin icon-error" />
                                <span>Password is incorrect</span>
                            </animated.div>
                        )}
                    </Transition>
                    <label className="login__label">Password</label>
                    <input className="login__input" ref={this.passwordRef} placeholder="Enter Password" type="password" />
                    <div className="login__submit icon-no-margin icon-arrow-tail-right" onClick={this.handleSubmit} />
                </form>
            </div>
        );
    }
}
