import React, { Component } from "react";
import { observer } from "mobx-react";
import { classnames } from "../../../helpers/classnames";
import { sliderService } from "../../../services/SliderService";
import { viewportService } from "../../../services/ViewportService";
import { Transition } from "react-spring/renderprops";
import { HorizontalRule, HorizontalRuleProps } from "../HorizontalRule";
import vennDiagramCenter from "../../../assets/images/values/venn-diagram-center.svg";
import awesomenessImage from "../../../assets/images/values/awesomeness-title.svg";

interface ValuesVennDiagramProps {
    startSlide?: number;
    endSlide?: number;
    values?: [string, string, string, string];
    isFixed?: boolean;
}

@observer
export class ValuesVennDiagram extends Component<ValuesVennDiagramProps> {
    render() {
        const {
            startSlide = 7,
            values = ["", "", "", ""],
            isFixed = true,
        } = this.props;
        const { width, height } = viewportService;
        const currentSlide = sliderService.currentSlide + 1;
        const prevSlide = sliderService.prevSlide + 1;
        const endSlide = this.props.endSlide || startSlide + 6;
        const activeSlide = !isFixed;
        const size =
            activeSlide || width < 400
                ? 200
                : width < 1000 && !activeSlide
                ? 300
                : 481;
        const scale =
            width > height ? width / (size * 0.2) : height / (size * 0.2);
        return (
            <>
                <Transition
                    items={currentSlide === endSlide && isFixed}
                    delay={prevSlide < currentSlide ? 300 : 0}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                >
                    {(isEndSlide) =>
                        isEndSlide &&
                        ((props) => (
                            <div
                                className={"position--fixed"}
                                style={{
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                    background: `url(${awesomenessImage}) center no-repeat`,
                                    backgroundSize: "contain",
                                    pointerEvents: "none",
                                    zIndex: 3,
                                    ...props,
                                }}
                            />
                        ))
                    }
                </Transition>
                <Transition
                    items={
                        currentSlide === startSlide ||
                        currentSlide >= endSlide - 1 ||
                        activeSlide
                    }
                    delay={currentSlide >= endSlide - 1 ? 300 : 0}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                >
                    {(show) =>
                        show &&
                        ((props) => (
                            <div
                                className={classnames(
                                    "display--flex flex-direction--column align-items--center pointer-events--none transition--default",
                                    {
                                        ["position--fixed"]: isFixed,
                                    }
                                )}
                                style={Object.assign(
                                    {
                                        width: size,
                                        opacity: 1,
                                        zIndex: 2,
                                    },
                                    isFixed && {
                                        top: "50%",
                                        left: "50%",
                                        transform:
                                            currentSlide === endSlide
                                                ? `translate3d(-50%, -50%, 0) scale(${
                                                      scale * 1.1
                                                  })`
                                                : "translate3d(-50%, -50%, 0)",
                                    },
                                    props
                                )}
                            >
                                <div
                                    className="position--relative width--100"
                                    style={{ paddingTop: "95%" }}
                                >
                                    {values.map(
                                        (value, i) =>
                                            i < values.length - 1 && (
                                                <Bubble
                                                    key={`${value}-${i}`}
                                                    name={value}
                                                    index={i}
                                                    size={size * 0.67}
                                                    active={
                                                        startSlide + i + 1 ===
                                                            currentSlide &&
                                                        activeSlide
                                                    }
                                                    showName={
                                                        startSlide ===
                                                            currentSlide &&
                                                        !activeSlide
                                                    }
                                                    width={width}
                                                />
                                            )
                                    )}
                                    <Transition
                                        items={
                                            currentSlide >= endSlide - 2 ||
                                            currentSlide === startSlide
                                        }
                                        delay={
                                            prevSlide < currentSlide ? 300 : 0
                                        }
                                        from={{ opacity: 0 }}
                                        enter={{ opacity: 1 }}
                                        leave={{ opacity: 0 }}
                                    >
                                        {(isEndSlides) =>
                                            isEndSlides &&
                                            ((props: any) => (
                                                <img
                                                    className="position--absolute"
                                                    src={vennDiagramCenter}
                                                    style={{
                                                        width: "34%",
                                                        top: "34.2%",
                                                        left: "50%",
                                                        transform:
                                                            "translate3d(-50%, 0, 0)",
                                                        ...props,
                                                    }}
                                                />
                                            ))
                                        }
                                    </Transition>
                                    <Transition
                                        items={currentSlide === startSlide}
                                        from={{ opacity: 0 }}
                                        enter={{ opacity: 1 }}
                                        leave={{ opacity: 0 }}
                                    >
                                        {(isStartSlide) =>
                                            isStartSlide &&
                                            ((props) => (
                                                <p
                                                    className="position--absolute color__white font-weight--bold text-align--center"
                                                    style={{
                                                        top: "54%",
                                                        left: "50%",
                                                        transform:
                                                            "translate3d(-50%, -50%, 0)",
                                                        width: "25%",
                                                        whiteSpace: "pre-wrap",
                                                        fontSize:
                                                            width < 700
                                                                ? 18
                                                                : size * 0.04,
                                                        ...props,
                                                    }}
                                                >
                                                    {width < 700
                                                        ? "4"
                                                        : values[3]}
                                                </p>
                                            ))
                                        }
                                    </Transition>
                                </div>
                                <Transition
                                    items={
                                        width < 700 &&
                                        startSlide === currentSlide &&
                                        isFixed
                                    }
                                    from={{ opacity: 0 }}
                                    enter={{ opacity: 1 }}
                                    leave={{ opacity: 0 }}
                                >
                                    {(isMobile: boolean) =>
                                        isMobile &&
                                        ((props: any) => (
                                            <ol
                                                className="margin-top--32"
                                                style={props}
                                            >
                                                {values.map((value) => (
                                                    <li
                                                        key={value}
                                                        className="padding-left--12 font-weight--bold color__grey--7"
                                                        style={{
                                                            fontSize: 16,
                                                            lineHeight: "30px",
                                                        }}
                                                    >
                                                        {value}
                                                    </li>
                                                ))}
                                            </ol>
                                        ))
                                    }
                                </Transition>
                            </div>
                        ))
                    }
                </Transition>
            </>
        );
    }
}

interface BubbleProps {
    name: string;
    index: number;
    size: number;
    active?: boolean;
    showName?: boolean;
    width: number;
}

const Bubble = (props: BubbleProps) => {
    const { name, index, size, active, showName, width } = props;
    const transitionStyle = "all 200ms ease-out";
    const horizontalRuleProps: HorizontalRuleProps = {
        color: "red",
        length: 32,
        style: {
            transition: transitionStyle,
            opacity: showName ? 1 : 0,
        },
    };
    const verticalOffset = size * 0.18;
    const horizontalOffset = size * 0.65;
    const borderSize = active ? 4 : 0;
    const circleSize = active ? size + 2 * borderSize : size;
    const fontSize = showName && width < 1000 ? 18 : showName ? 24 : 0;
    const bubbleStyles = {
        top: index === 0 ? -borderSize : undefined,
        right: index === 1 ? -borderSize : undefined,
        bottom: index !== 0 ? -borderSize : undefined,
        left: index === 2 ? -borderSize : index === 0 ? "50%" : undefined,
        transform: index === 0 ? "translate3d(-50%, 0, 0)" : undefined,
    };
    const textStyles = {
        top: index === 0 ? verticalOffset : undefined,
        right: index === 2 ? horizontalOffset : undefined,
        bottom: index !== 0 ? verticalOffset * 1.8 : undefined,
        left: index === 1 ? horizontalOffset : index === 0 ? "50%" : undefined,
        transform: index === 0 ? "translate3d(-16px, 0, 0)" : undefined,
    };
    return (
        <div
            className="position--absolute"
            style={Object.assign(
                {
                    width: circleSize,
                    height: circleSize,
                    transition: "all 500ms ease-out",
                },
                bubbleStyles,
                active && { zIndex: 2 }
            )}
        >
            <div
                className="position--absolute background-color__grey--10"
                style={{
                    border: active ? `${borderSize}px solid white` : undefined,
                    borderRadius: "50%",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: active ? 0.7 : 0.2,
                }}
            />
            <p
                className="position--absolute font-weight--bold color__grey--5 display--flex align-items--center"
                style={Object.assign(
                    {
                        fontSize: fontSize,
                        whiteSpace: "nowrap",
                        transition: transitionStyle,
                    },
                    textStyles
                )}
            >
                {index !== 2 && (
                    <HorizontalRule
                        className="margin-right--12"
                        {...horizontalRuleProps}
                    />
                )}
                <span>{width < 700 ? index + 1 : name}</span>
                {index === 2 && (
                    <HorizontalRule
                        className="margin-left--12"
                        {...horizontalRuleProps}
                    />
                )}
            </p>
        </div>
    );
};
