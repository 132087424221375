import React from 'react';
import { classnames } from "../../helpers/classnames";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { HorizontalRule } from "../Components/HorizontalRule";
import { GeneralContent } from "./GeneralContent";
import { trColor } from "../Themes/Chainlink";
import titleImage from "../../assets/images/our-why/our-why-title.jpg";
import getFasterImage from "../../assets/images/our-why/get-faster-background.jpg";
import getFasterLogo from "../../assets/images/our-why/get-faster-logo.svg";
import planningImage from "../../assets/images/our-why/planning.jpg";
import trainingImage from "../../assets/images/our-why/training.jpg";
import analyticsImage from "../../assets/images/our-why/analytics.jpg";
import pillarIcon from "../../assets/images/our-why/pillar-icon.svg";
import highFiveIcon from "../../assets/images/our-why/high-five-icon.svg";

export const OurWhyView = (renderProps: any) => (
    <Slider showTitle {...renderProps}>
        <Slide theme="light" backgroundColor="black" backgroundImage={titleImage} />
        <Slide theme="dark" backgroundColor="white">
            <GeneralContent theme="dark" maxWidth={750}>
                Our goal is simple – <span className="display--inline-block font-weight--extra-bold" style={{ borderBottom: `3px solid ${trColor.red}` }}>make cyclists faster</span>
            </GeneralContent>
        </Slide>
        <Slide theme="light" backgroundColor="black" backgroundImage={getFasterImage}>
            <GeneralContent theme="light" maxWidth={825}>
                <img className="width--100" src={getFasterLogo} alt="Get Faster" />
            </GeneralContent>
        </Slide>
        <Slide theme="dark" backgroundColor="white">
            <GeneralContent theme="dark" maxWidth={654} text="We exist to improve the performance, fitness, and cycling IQ of our athletes." />
        </Slide>
        <Slide theme="dark" backgroundColor="white">
            <GeneralContent theme="dark" maxWidth={782}>
                We simplify the complexities of <span className="font-weight--extra-bold">planning</span>, <span className="font-weight--extra-bold">training</span>, and <span className="font-weight--extra-bold">analytics</span> while keeping them guided and personalized.
            </GeneralContent>
        </Slide>
        <Slide theme="light" backgroundColor="black">
            <Pillars
                pillars={[
                    {
                        imageSrc: planningImage,
                        title: "Planning",
                        content: "Planning training around specfic goals, events and lifestyles is the best way to improve."
                    }, {
                        imageSrc: trainingImage,
                        title: "Training",
                        content: "Structured, power-based interval training is the most time-efficient and effective way to get faster on the bike."
                    }, {
                        imageSrc: analyticsImage,
                        title: "Analytics",
                        content: "Analyzing training effort in a regular and focused way allows understanding on what impacts performance."
                    }
                ]}
            />
        </Slide>
        <Slide theme="dark" backgroundColor="white">
            <GeneralContent theme="dark" maxWidth={782}>
                Our approach to training allows cyclists to get faster in the most time-effective way possible.
                <p className="padding-top--60"><img src={highFiveIcon} alt="High Five" /></p>
            </GeneralContent>
        </Slide>
    </Slider>
);

type Pillar = {
    imageSrc: string;
    title: string;
    content: string;
}

const Pillars = (props: { pillars: Pillar[] }) => {
    const { pillars } = props;
    return (
        <div className="width--100" style={{ maxWidth: 1222 }}>
            <div className="margin-bottom--40"><img src={pillarIcon} alt="TrainerRoad's Pillars" /></div>
            <div className="md__display--flex">
                {pillars.map((pillar, i) => (
                    <div
                        key={pillar.title}
                        className={classnames(
                            "md__flex--1", {
                                ["margin-bottom--40 md__margin-bottom--none md__margin-right--32"]: i !== pillars.length - 1
                            }
                        )}
                        style={{ maxWidth: 346 }}
                    >
                        <img src={pillar.imageSrc} alt={pillar.title} className="display--none sm__display--block width--100" />
                        <p className="display--flex align-items--center font-weight--bold font-size--18 color__white margin-top--12 md__margin-top--24 margin-bottom--8"><HorizontalRule color="grey10" length={24} className="margin-right--12" /> {pillar.title}</p>
                        <p className="font-size--14 color__grey--11" style={{ lineHeight: "21px" }}>{pillar.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
