import { Theme } from "./Theme";
import { trColor } from "./Chainlink";

export const DarkTheme: Theme = {
    header: {
        indicator: trColor.red,
        mark: trColor.red,
        typeface: trColor.grey7,
        navLink: {
            indicator: trColor.red,
            number: trColor.grey10,
            text: {
                default: trColor.grey8,
                hover: trColor.grey6,
            },
        }
    },
    navigation: {
        active: trColor.grey9,
        inactive: trColor.grey12,
    },
    title: {
        arrow: trColor.grey9,
        number: trColor.grey12,
        text: trColor.grey6,
    }
}
