import React, { Component } from 'react';
import { Provider, observer } from "mobx-react";
import { globalStores } from "./globalStores/globalStores";
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import { loginService } from "./services/LoginService";
import { Header } from "./renderer/Components/Header";
import { Login } from "./renderer/Views/Login/Login";
import { SwitchToPortrait } from "./renderer/Views/SwitchToPortrait";
import { PageTransitioner } from "./PageTransitioner";
// Preloading title images
import ourWhyTitleImage from "./assets/images/our-why/our-why-title.jpg";
import valuesTitleImage from "./assets/images/values/values-title.jpg";
import brandTitleImage from "./assets/images/brand/brand-title.jpg";
import audienceTitleImage from "./assets/images/audience/audience-title.jpg";


@observer
export default class App extends Component {
    componentDidMount() {
        const preloadedImages = [
            ourWhyTitleImage,
            valuesTitleImage,
            brandTitleImage,
            audienceTitleImage
        ];

        preloadedImages.forEach(imageSrc => {
            new Image().src = imageSrc;
        })
    }

    render() {
        const isLoggedIn = loginService.loggedIn;
        return (
            <Provider {...globalStores}>
                <BrowserRouter basename="/">
                    <div className="App height--100">
                        <Header />
                        <Login />
                        <SwitchToPortrait />
                        {isLoggedIn && <PageTransitioner />}
                    </div>
                </BrowserRouter>
            </Provider>
        );
    }
}
