import React, { CSSProperties } from "react";
import { trColor } from "../Themes/Chainlink";

export interface HorizontalRuleProps {
    color?: keyof typeof trColor;
    size?: number;
    length?: number | string;
    className?: string;
    style?: CSSProperties;
}

export const HorizontalRule = (props: HorizontalRuleProps) => {
    const { color = "red", size = 1, length = 32, className, style } = props;
    return (
        <span
            className={className}
            style={{
                backgroundColor: trColor[color],
                width: length,
                height: size,
                ...style
            }}
        />
    );
}
