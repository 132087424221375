export const trColor = {
    black: "#151515",
    grey1: "#1d1d1d",
    grey2: "#252525",
    grey3: "#2e2e2e",
    grey4: "#373737",
    grey5: "#3f3f3f",
    grey6: "#505050",
    grey7: "#6a6a6a",
    grey8: "#838383",
    grey9: "#9d9d9d",
    grey10: "#b6b6b6",
    grey11: "#d0d0d0",
    grey12: "#e0e0e0",
    grey13: "#e9e9e9",
    grey14: "#f1f1f1",
    grey15: "#fafafa",
    white: "#ffffff",

    red1: "#801810",
    red2: "#da291c",
    red3: "#e53f33",
    red: "#da291c",
    redHover: "#e53f33",
    error: "#801810",

    green1: "#377c1c",
    green2: "#439f1f",
    green3: "#5bb836",
    green4: "#7acd59",
    green5: "#bae1a2",
    green: "#439f1f",
    greenHover: "#5bb836",

    blue1: "#004062",
    blue2: "#1271b4",
    blue3: "#1482c8",
    blue4: "#1795dc",
    blue5: "#19a2eb",
    blue6: "#51bef1",
    blue: "#1482c8",
    blueHover: "#1795dc",

    yellow1: "#fbb000",
    yellow2: "#fac800",
    yellow3: "#fad342",
    yellow4: "#fdebaf",
    yellow: "#fac800",

    brown1: "#bd7019",
    brown2: "#e28a27",
    brown: "#bd7019",

    temp: "fuchsia",
};
