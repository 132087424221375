import React, { useContext } from 'react';
import { useTransition, animated } from 'react-spring'
import { Route, Switch } from 'react-router';
import {
    ErrorView,
    TableOfContentsView,
    OurWhyView,
    CompanyValuesView,
    BrandView,
    AudienceView
} from "./renderer/Views";
//@ts-ignore
import { __RouterContext } from 'react-router';

type Page = {
    name: string;
    shortName?: string;
    to: string;
    component: any
}

export const pages: Page[] = [
    {name: "Our Why", to: "/our-why", component: OurWhyView},
    {name: "Company Values", shortName: "Values", to: "/company-values", component: CompanyValuesView},
    {name: "Brand", to: "/brand", component: BrandView},
    {name: "Audience", to: "/audience", component: AudienceView},
];

export const PageTransitioner = () => {
    const { location } = useContext(__RouterContext);
    const transitions = useTransition(location, (location: any) => location.pathname, {
        from: { transform: 'translate3d(100%, 0, 0)'},
        enter: { transform: 'translate3d(0, 0, 0)'},
        leave: { transform: 'translate3d(-100%, 0, 0)'}
    })
    return (
        //@ts-ignore
        transitions.map(({item, props, key}) => (
            <animated.div
                style={{...props, position: "fixed", top: 0, right: 0, bottom: 0, left: 0}}
                key={key}
            >
                <Switch location={item}>
                    <Route path="/" exact render={renderProps => <TableOfContentsView {...renderProps} />} />
                    {pages.map((page, i) => {
                        const Elem: any = page.component;
                        return (
                            <Route key={page.to} path={page.to} exact render={renderProps => <Elem {...renderProps} />} />
                        );
                    })}
                    <Route render={renderProps => <ErrorView {...renderProps} />} />
                </Switch>
            </animated.div>
        ))
    )
}
