import React from 'react';
import { observer } from "mobx-react";
import { viewportService } from "../../services/ViewportService";
import { Slider } from "../Components/Slider/Slider";
import { Slide } from "../Components/Slider/Slide";
import { GeneralContent } from "./GeneralContent";
import { ListContent } from "./ListContent";
import { HeadingContent } from "./HeadingContent";
import titleImage from "../../assets/images/brand/brand-title-v2.jpg";
import trainingImage from "../../assets/images/brand/training-background.jpg";
import educationImage from "../../assets/images/brand/education-background.jpg";
import guidanceImage from "../../assets/images/brand/guidance-background.jpg";
import pinkySwearImage from "../../assets/images/brand/pinky-swear-background.png";

export const BrandView = observer((renderProps: any) => {
    const { width, height } = viewportService;
    return (
        <Slider showTitle {...renderProps}>
            <Slide theme="light" backgroundColor="black" backgroundImage={titleImage} />
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={844}>
                    <span className="font-weight--extra-bold">Our brand is all around us</span> &mdash; it’s what we choose to say and how we say it, it’s in our colors and imagery, and it’s the interactions we have with our athletes and each other.
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={940} alignment="left">
                    We <span className="font-weight--extra-bold">make a promise</span> to those athletes. A promise that:
                    <ListContent
                        list={[
                            "They will get faster",
                            "We care about the details and we’ll handle them",
                            "We strive to improve, just like they do"
                        ]}
                        color="grey7"
                    />
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={940}>
                    Who we are as a company &mdash; <span className="font-weight--extra-bold">internally and externally</span> &mdash; is reflected by our brand promises and values.
                </GeneralContent>
            </Slide>
            <Slide theme="light" backgroundColor="black" backgroundImage={trainingImage}>
                <GeneralContent theme="light" maxWidth={800} alignment="left">
                    <HeadingContent heading="Training" content="Everything we do for our product and athletes is to improve their fitness and make them faster on the bike. Effective training is our number one focus." />
                </GeneralContent>
            </Slide>
            <Slide theme="light" backgroundColor="black" backgroundImage={educationImage}>
                <GeneralContent theme="light" maxWidth={800} alignment="left">
                    <HeadingContent heading="Education">
                        We want our athletes to be the smartest they can be to <em>a)</em> train more effectively and <em>b)</em> perform at their highest level.
                    </HeadingContent>
                </GeneralContent>
            </Slide>
            <Slide theme="light" backgroundColor="black" backgroundImage={guidanceImage}>
                <GeneralContent theme="light" maxWidth={800} alignment="left">
                    <HeadingContent heading="Guidance">
                        We guide our athletes to better understand their training journey, to make the right decisions, and to help them get back on track when they falter.
                    </HeadingContent>
                </GeneralContent>
            </Slide>
            <Slide theme="dark" backgroundColor="white">
                <GeneralContent theme="dark" maxWidth={800}>
                    These values impact and guide every decision we make at TrainerRoad.
                </GeneralContent>
            </Slide>
        </Slider>
    );
});
