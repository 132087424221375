import React from 'react';
import { observer } from "mobx-react";
import { themes } from "../../services/ThemeService";
import { viewportService } from "../../services/ViewportService";
import { trColor } from "../Themes/Chainlink";

export const GeneralContent = observer((props: {
    text?: string;
    children?: React.ReactNode;
    theme: keyof typeof themes,
    maxWidth?: number;
    alignment?: "center" | "left";
}) => {
    const { text, children, theme, maxWidth = 660, alignment = "center" } = props;
    const { width } = viewportService;
    const fontSize = width < 660 ? 24 : 36;
    return (
        <div
            className={`font-weight--medium text-align--${alignment}`}
            style={{
                fontSize: fontSize,
                maxWidth: maxWidth,
                lineHeight: 1.2,
                color: theme === "light" ? trColor.white : trColor.grey4
            }}
        >
            {text}
            {children}
        </div>
    );
});
