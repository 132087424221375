import { Theme } from "./Theme";
import { trColor } from "./Chainlink";

export const LightTheme: Theme = {
    header: {
        indicator: trColor.white,
        mark: trColor.white,
        typeface: trColor.white,
        navLink: {
            indicator: trColor.white,
            number: trColor.white,
            text: {
                default: trColor.grey12,
                hover: trColor.grey15,
            },
        }
    },
    navigation: {
        active: trColor.grey15,
        inactive: trColor.grey12,
    },
    title: {
        arrow: trColor.white,
        number: "rgba(235,235,235,.8)",
        text: trColor.white,
    }
}
