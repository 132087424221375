import React, { Component, CSSProperties } from 'react';
import { observer } from "mobx-react";
import { sliderService } from "../../services/SliderService";
import { Spring } from "react-spring/renderprops";
import { easeQuadIn } from "d3-ease";

interface IProps {
    style: CSSProperties;
    children: any;
    prevDelay?: number;
    nextDelay?: number;
    duration?: number;
}

@observer
export class ThemeSpring extends Component<IProps> {
    render() {
        const { style, children, prevDelay = 0, nextDelay = 300, duration = 200 } = this.props;
        const { currentSlide, prevSlide } = sliderService;
        const DURATION = duration;
        const DELAY = prevSlide < currentSlide ? nextDelay : prevDelay;
        return (
            <Spring
                config={{ duration: DURATION, delay: DELAY, easing: easeQuadIn }}
                from={style}
                to={style}
                onRest={this.handlePrevSlide}
            >
                {props => React.Children.map(children, child => React.cloneElement(child, { style: { ...child.props.style, ...props } }))}
            </Spring>
        );
    }

    handlePrevSlide = () => {
        sliderService.updatePrevSlide(sliderService.currentSlide);
    }
}
