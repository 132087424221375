import {
    loginService,
    sliderService,
    themeService,
    viewportService,
} from "../services";

export const globalStores = {
    loginService,
    sliderService,
    themeService,
    viewportService,
};
